import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { ExternalLinkIcon } from '@heroicons/react/solid'

const samples = [
  { client: 'Riot Racer', description: 'NFT web3 application.', role: 'Full Stack Development', technology: 'React, Web3.js, Solidity, Node, MySQL', url: 'https://app.riot.fun' },
  { client: 'ChatterPy', description: 'Marketing Software', role: 'Frontend Development', technology: 'React, Django, AWS, PostgreSQL', url: 'https://www.chatterpy.com/' },
  { client: 'Question Pro', description: 'The online collaborative whiteboard platform to bring teams together, anytime, anywhere.', role: 'Full Stack Development', technology: 'React, Typescript, React-Konva, Nestjs, Jest', url: 'https://fresco.herokuapp.com/' },
  { client: 'DLD VIP', description: 'Digital webinars and physical products', role: 'Full Stack Development', technology: 'React, Node, AWS', url: 'https://dld-vip.com/' },
  { client: 'Advinow', description: 'Medical Appointment System based on AI', role: 'Full Stack Development', technology: 'React, Typescript, Django', url: 'https://devtools.advinow.net/PatientApp/' },
  { client: 'Babypips', description: 'Forex Trading LMS', role: 'Full Stack Development', technology: 'React, Typescript, NextJs, Django, AWS', url: 'https://www.babypips.com/' },
  { client: 'Stickerbeat', description: 'Ecommerce', role: 'Full Stack Development', technology: 'React, Gatsby, Node, Shopify', url: 'https://stickerbeat.com/' },
  // More people...
]

const SampleWork = () => {
  return (
    <>
    <Header />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Sample Work
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              Some of the best examples of the type of work we are capable of.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Client
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Technology
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {samples.map((sample, sampleIdx) => (
                    <tr key={sample.email} className={sampleIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{sample.client}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{sample.description}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{sample.technology}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{sample.role}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a href={sample.url} className="text-indigo-600 hover:text-indigo-900">
                          <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SampleWork
